import { XF } from "../../XF";

/** sets the user's vote on a poll based on threadId
 *
 * note:
 * - if the user has already voted, this will override their current vote
 * - if the user is not logged in, ValueToFollow login overlay will be shown
 */
export async function voteOnPoll(
  threadId: string | number,
  option: string | number
) {
  const result = await XF.ajax(
    "post",
    `threads/${threadId}/poll/vote`,
    {
      responses: [option],
    },
    undefined,
    { skipDefaultSuccess: true }
  );
  if (result.html?.title === "Poll results") return;
  if (result.html?.title === "Log in") {
    XF.setupHtmlInsert(result.html, ($html, container) => {
      XF.showOverlay(
        XF.getOverlayHtml({
          title: container.title,
          html: $html,
        })
      );
    });
    throw new Error("Not logged in");
  }
  throw new Error(result.html?.title || result.message);
}
