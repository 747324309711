import { ComponentProps } from "preact";
import { useMemo } from "preact/hooks";
import { gtmSummaryImage } from "../../../common/gtm-ids";
import { getCFSrc } from "../../../common/helper";
import { toGtmContext } from "../../../common/toGtmContext";
import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
import { ShortNumber } from "../ShortNumber/ShortNumber";
import { SummaryBase } from "./SummaryBase";

export function SummaryGallery({
  summary,
  first,
  ...props
}: ComponentProps<typeof SummaryBase> & {
  summary: ComponentProps<typeof SummaryBase>["summary"] & {
    node: { __typename: "DiscoveryV4GalleryStory" };
  };
}) {
  const imagePosts = useMemo(
    () => summary.node.posts.filter((i) => i.images?.length),
    [summary]
  );

  return (
    <SummaryBase {...props} summary={summary} first={first}>
      <div class="summary-count">
        <ShortNumber number={imagePosts.length} /> image post
        {imagePosts.length === 1 ? "" : "s"} in this thread
      </div>
      <HorizontalScroller Tag="ol" class="summary-images">
        {imagePosts.slice(0, 20).map((i) => (
          <li key={i.id}>
            <a
              href={`/posts/${i.id}`}
              data-gtm={gtmSummaryImage}
              data-gtm-context={toGtmContext({
                postId: i.id,
                storyId: summary.node.id,
              })}
            >
              <img
                width={i.images?.[0].width}
                height={i.images?.[0].height}
                src={getCFSrc(352, i.images?.[0].url || "")}
                alt={`Preview of ${i.author.username}'s post`}
                loading={first ? "eager" : "lazy"}
              />
              <span>{i.author.username}</span>
            </a>
          </li>
        ))}
      </HorizontalScroller>
    </SummaryBase>
  );
}
