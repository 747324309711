import escapeStringRegexp from "escape-string-regexp";
import { ComponentProps } from "preact";
import { useMemo } from "preact/hooks";
import { gtmSummaryMentionReadmore } from "../../../common/gtm-ids";
import { getCFSrc } from "../../../common/helper";
import { toGtmContext } from "../../../common/toGtmContext";
import { getSummaries } from "../../services/feed-content";
import { BBCode } from "../BBCode/BBCode";
import { Byline } from "../Byline/Byline";

export function SummaryMention({
  post,
  highlight,
  gtmContext,
}: {
  post: (Awaited<ReturnType<typeof getSummaries>>["edges"][number]["node"] & {
    __typename: "DiscoveryV4BrandStory";
  })["brands"][number]["posts"][number];
  highlight?: string;
  gtmContext: ComponentProps<typeof Byline>["gtmContext"];
}) {
  const urlPost = `/posts/${post.id}`;
  const text = useMemo(
    () =>
      highlight
        ? post.text.replace(
            new RegExp(`\\b(${escapeStringRegexp(highlight)})\\b`, "ig"),
            `[b]$1[/b]`
          )
        : post.text,
    [highlight, post.text]
  );
  const firstImage = post.images?.[0];
  return (
    <>
      <Byline
        userid={post.author.id}
        username={post.author.username}
        avatarUrl={post.author.avatarUrl}
        avatarSize="xxxs"
        date={post.date}
        urlDate={urlPost}
        gtmContext={gtmContext}
      />
      <div class="summary-mention-body">
        <div>
          <BBCode media={false} quotes={false} class="summary-mention-text">
            {text}
          </BBCode>
          <a
            href={urlPost}
            data-gtm={gtmSummaryMentionReadmore}
            data-gtm-context={toGtmContext({ id: post.id, ...gtmContext })}
          >
            See full post in thread
          </a>
        </div>
        {firstImage && (
          <img
            class="summary-mention-image"
            src={getCFSrc(320, firstImage.url || "")}
            width={firstImage.width}
            height={firstImage.height}
            alt={`Preview of ${post.author.username}'s post`}
            loading="lazy"
          />
        )}
      </div>
    </>
  );
}
