export const gtmAvatar = "avatar";
export const gtmBylineDate = "byline_date";
export const gtmBylineReplies = "byline_replies";
export const gtmHomepageLoadmore = "homepage_loadmore";
export const gtmHometabsTab = "hometabs_tab";
export const gtmCategoriesCategory = "categories_category";
export const gtmCategoriesClear = "categories_clear";
export const gtmCategoriesApply = "categories_apply";
export const gtmEditorAlttext = "editor_alttext";
export const gtmSummariesLoadmore = "summaries_loadmore";
export const gtmSummariesBrand = "summaries_brand";
export const gtmSummariesEnd = "summaries_end";
export const gtmSummariesOpenfilters = "summaries_openfilters";
export const gtmSummaryImage = "summary_image";
export const gtmSummaryNode = "summary_node";
export const gtmSummaryReadmore = "summary_readmore";
export const gtmSummaryMentionReadmore = "summary_mention_readmore";
export const gtmSummaryTitle = "summary_title";
export const gtmSummaryPollMentions = "summary_poll_mentions";
export const gtmSummaryPollVote = "summary_poll_vote";
export const gtmSummaryPollSeeresults = "summary_poll_seeresults";
export const gtmSummaryPollReplies = "summary_poll_replies";
export const gtmUsername = "username";
