import { ComponentType } from "preact";
import { Loader } from "../Loader/Loader";

export function SidebarWidget<
  T extends {
    title: string;
    url: string;
  },
>({
  title,
  link,
  linkTitle,
  items,
  qidName,
  Info,
}: {
  title: string;
  link: string;
  linkTitle: string;
  items: T[] | null;
  qidName: string;
  Info: ComponentType<{
    item: T;
  }>;
}) {
  return (
    <>
      <h3 class="title" qid={`${qidName}-header`}>
        {title}
      </h3>
      <a
        class="bold sidebar-member-link mb-10"
        rel="nofollow"
        href={link}
        qid={`${qidName}-link`}
      >
        {linkTitle}
      </a>
      {items?.map((item) => (
        <div
          key={item.url}
          class="sidebar-item-wrapper"
          qid={`${qidName}-item`}
        >
          <a href={item.url} title={item.title} qid={`${qidName}-item-name`}>
            {item.title}
          </a>
          <Info item={item} />
        </div>
      )) || <Loader />}
    </>
  );
}
