import { ComponentProps } from "preact";
import { useMemo } from "preact/hooks";
import { gtmSummariesBrand } from "../../../common/gtm-ids";
import { ShortNumber } from "../ShortNumber/ShortNumber";
import { SummaryBase } from "./SummaryBase";
import { SummaryMentionGroups } from "./SummaryMentionGroups";

export function SummaryBrand({
  summary,
  ...props
}: ComponentProps<typeof SummaryBase> & {
  summary: ComponentProps<typeof SummaryBase>["summary"] & {
    node: { __typename: "DiscoveryV4BrandStory" };
  };
}) {
  const groups = useMemo(
    () =>
      summary.node.brands.map((i) => ({
        key: i.name,
        title: i.name,
        mentions: i.posts,
      })),
    [summary.node.brands]
  );
  return (
    <SummaryBase {...props} summary={summary}>
      <div class="summary-count">
        <ShortNumber number={summary.node.brands.length} /> brand
        {summary.node.brands.length === 1 ? "" : "s"} in this thread
      </div>
      <SummaryMentionGroups
        summary={summary}
        groups={groups}
        gtmGroup={gtmSummariesBrand}
      />
    </SummaryBase>
  );
}
