import clsx from "clsx";
import { ComponentProps } from "preact";
import { gtmBylineDate, gtmBylineReplies } from "../../../common/gtm-ids";
import { toGtmContext } from "../../../common/toGtmContext";
import { Avatar } from "../Avatar/Avatar";
import { RelativeDate } from "../RelativeDate/RelativeDate";
import { ShortNumber } from "../ShortNumber/ShortNumber";
import { Username } from "../Username/Username";

export function Byline({
  userid,
  username,
  avatarUrl,
  avatarSize = "xxs",
  date,
  replies,
  showAvatar = true,
  urlDate,
  gtmContext,
  ...props
}: ComponentProps<"div"> & {
  userid?: number;
  username?: string;
  avatarUrl?: string;
  avatarSize?: ComponentProps<typeof Avatar>["size"];
  replies?: number;
  showAvatar?: boolean;
  gtmContext?: Record<string, string>;
} & (
    | {
        date: number;
        urlDate: string;
      }
    | {
        date?: never;
        urlDate?: never;
      }
  )) {
  return (
    <div {...props} class={clsx(props.class, "byline")}>
      {avatarUrl !== undefined && showAvatar && (
        <Avatar
          user={{
            avatar_url: avatarUrl,
            user_id: userid,
            username,
          }}
          size={avatarSize}
          gtmContext={gtmContext}
        />
      )}
      {username !== undefined && (
        <Username username={username} userid={userid} class="byline-username" />
      )}
      {date && (
        <a
          class="byline-date"
          href={urlDate}
          data-gtm={gtmBylineDate}
          data-gtm-context={toGtmContext({ date, ...gtmContext })}
        >
          <RelativeDate date={date} />
        </a>
      )}
      {replies !== undefined && (
        <span
          class="byline-replies"
          data-gtm={gtmBylineReplies}
          data-gtm-context={toGtmContext({ replies, ...gtmContext })}
        >
          <ShortNumber number={replies} /> replies
        </span>
      )}
    </div>
  );
}
