import clsx from "clsx";
import { ComponentProps } from "preact";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";
import "./HorizontalScroller.less";

export default function HorizontalScroller({
  Tag = "ul",
  children,
  arrows = false,
  ...props
}: ComponentProps<"div"> & { Tag?: "ol" | "ul"; arrows?: boolean }) {
  const refList = useRef<HTMLOListElement | HTMLUListElement>(null);
  useEffect(() => {
    const elList = refList.current;
    if (!elList) return undefined;
    const parent = elList;

    let startX = 0;
    let scrollLeft = 0;
    let isDown = false;
    let avoidClick = false;

    function mouseDown(e: MouseEvent) {
      isDown = true;
      startX = e.pageX - parent.offsetLeft;
      scrollLeft = parent.scrollLeft;
    }
    function mouseUp(e: Event) {
      isDown = false;
      e.preventDefault();
      if (avoidClick) {
        avoidClick = false;
        parent.style.cursor = "";
        Array.from(parent.children).forEach((i) => {
          (i as HTMLElement).style.pointerEvents = "";
        });
      }
    }
    function mouseLeave() {
      isDown = false;
    }
    function mouseMove(e: MouseEvent) {
      if (isDown) {
        e.preventDefault();
        const x = e.pageX - parent.offsetLeft;
        const walkX = (x - startX) * 5;
        parent.scrollLeft = scrollLeft - walkX;
        if (!avoidClick && Math.abs(parent.scrollLeft - scrollLeft) > 5) {
          parent.style.cursor = "grabbing";
          avoidClick = true;
          Array.from(parent.children).forEach((i) => {
            (i as HTMLElement).style.pointerEvents = "none";
          });
        }
      }
    }

    parent.addEventListener("mousedown", mouseDown as EventListener);
    window.addEventListener("mouseup", mouseUp);
    parent.addEventListener("mouseleave", mouseLeave);
    parent.addEventListener("mousemove", mouseMove as EventListener);

    return () => {
      parent.removeEventListener("mousedown", mouseDown as EventListener);
      window.removeEventListener("mouseup", mouseUp);
      parent.removeEventListener("mouseleave", mouseLeave);
      parent.removeEventListener("mousemove", mouseMove as EventListener);
    };
  }, []);

  const scrollPrev = useCallback(() => {
    const elList = refList.current;
    if (!elList) return;
    elList.scrollBy({
      left: -elList.offsetWidth * (2 / 3),
      behavior: "smooth",
    });
  }, []);

  const scrollNext = useCallback(() => {
    const elList = refList.current;
    if (!elList) return;
    elList.scrollBy({
      left: elList.offsetWidth * (2 / 3),
      behavior: "smooth",
    });
  }, []);

  const [arrowState, setArrowState] = useState<"start" | "mid" | "end">(
    "start"
  );
  useEffect(() => {
    const elList = refList.current;
    if (!elList) return undefined;
    const onScroll = () => {
      if (elList.scrollLeft <= 0) {
        setArrowState("start");
      } else if (elList.scrollLeft >= elList.scrollWidth - elList.clientWidth) {
        setArrowState("end");
      } else {
        setArrowState("mid");
      }
    };
    elList.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      elList.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div {...props} class={clsx(props.class, "scroller")}>
      {arrows && (
        <button
          disabled={arrowState === "start"}
          class="scroller-arrow scroller-arrow--prev"
          title="Previous"
          type="button"
          onClick={scrollPrev}
        >
          <i class="fa fa-caret-left" />
        </button>
      )}
      {arrows && (
        <button
          disabled={arrowState === "end"}
          class="scroller-arrow scroller-arrow--next"
          title="Next"
          type="button"
          onClick={scrollNext}
        >
          <i class="fa fa-caret-right" />
        </button>
      )}
      <Tag
        class="scroller-list"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={refList}
      >
        {children}
      </Tag>
    </div>
  );
}
