import { useEffect, useState } from "preact/hooks";
import { strings } from "../../homepage-strings";
import { getTopForums } from "../../services/nodes";
import { ShortNumber } from "../ShortNumber/ShortNumber";
import { SidebarWidget } from "../SidebarWidget/SidebarWidget";

function TopForumsInfo({
  item: { views, messageCount },
}: {
  item: Awaited<ReturnType<typeof getTopForums>>[number];
}) {
  return messageCount && views ? (
    <div class="sidebar-item-stats-wrapper">
      <div class="sidebar-item-stat">
        <i class="message-icon" />
        <ShortNumber number={messageCount} />
      </div>
      <div class="sidebar-item-stat">
        <i class="eye-icon" />
        <ShortNumber number={views} />
      </div>
    </div>
  ) : null;
}

export function TopForumsWidget() {
  const [data, setData] = useState<Awaited<
    ReturnType<typeof getTopForums>
  > | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setData(await getTopForums());
      } catch (error) {
        console.error("Failed to fetch top forums", error);
      }
    })();
  }, []);

  return (
    <SidebarWidget
      title={strings.OUR_TOP_FORUMS}
      link="/forums"
      linkTitle={strings.VIEW_ALL}
      items={data}
      qidName="top-forums"
      Info={TopForumsInfo}
    />
  );
}
