import { ComponentProps } from "preact";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "preact/hooks";
import { XF } from "../../../XF";
import {
  gtmCategoriesClear,
  gtmSummariesEnd,
  gtmSummariesLoadmore,
  gtmSummariesOpenfilters,
} from "../../../common/gtm-ids";
import { Modal } from "../../../primary-client/components/Modal";
import { getCategories, getSummaries } from "../../services/feed-content";
import { Loader } from "../Loader/Loader";
import { RelativeDate } from "../RelativeDate/RelativeDate";
import { SummariesCategories } from "./SummariesCategories";
import { Summary } from "./Summary";

const storageKey = "categories-selected";

const defaultCategories = ["following"];
let initialSelected: string[] =
  XF.LocalStorage.getJson(storageKey) || defaultCategories;
if (!Array.isArray(initialSelected)) {
  initialSelected = defaultCategories;
}

export function Summaries({
  numFollowed,
  polls = false,
}: {
  numFollowed: number;
  polls?: boolean;
}) {
  const refAsync = useRef(0);
  const [state, setState] = useState<"loading" | "loaded" | "error">("loading");
  const [summaries, setSummaries] =
    useState<Awaited<ReturnType<typeof getSummaries>>>();
  const [categories, setCategories] =
    useState<Awaited<ReturnType<typeof getCategories>>>();
  const [selectedCategories, setSelectedCategories] = useState(initialSelected);
  useEffect(() => {
    (async () => {
      const current = ++refAsync.current;
      try {
        setState("loading");
        setSummaries(undefined);
        const data = await getSummaries(
          "",
          10,
          selectedCategories.filter((i) => i)
        );
        if (refAsync.current !== current) return;
        setSummaries(data);
        setState("loaded");
      } catch (err) {
        if (refAsync.current !== current) return;
        setState("error");
        console.error("Failed to load summaries", err);
      }
    })();
  }, [selectedCategories]);
  const loadMore = useCallback<
    NonNullable<ComponentProps<"button">["onClick"]>
  >(
    async (event) => {
      const current = ++refAsync.current;
      setState("loading");
      try {
        const newSummaries = await getSummaries(
          event.currentTarget.value,
          10,
          selectedCategories.filter((i) => i)
        );
        if (refAsync.current !== current) return;
        setSummaries((s) => ({
          lastUpdated: s?.lastUpdated || newSummaries.lastUpdated,
          pageInfo: newSummaries.pageInfo,
          edges: (s?.edges || []).concat(newSummaries.edges),
        }));
        setState("loaded");
      } catch (err) {
        if (refAsync.current !== current) return;
        setState("error");
        console.error("Failed to load summaries", err);
      }
    },
    [selectedCategories]
  );

  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const toggleCategories = useCallback(() => setCategoriesOpen((s) => !s), []);
  const clearCategories = useCallback(() => {
    setSelectedCategories([]);
    setCategoriesOpen(false);
  }, []);
  const applyCategories = useCallback((c: string[]) => {
    setSelectedCategories(c);
    setCategoriesOpen(false);
  }, []);
  useEffect(() => {
    XF.LocalStorage.setJson(storageKey, selectedCategories);
  }, [selectedCategories]);

  const count = useMemo(() => {
    if (selectedCategories.includes("following")) {
      return selectedCategories.length - 1 + numFollowed;
    }
    return selectedCategories.length;
  }, [numFollowed, selectedCategories]);

  const edges = useMemo(() => {
    const allowedTypes: NonNullable<
      NonNullable<typeof summaries>["edges"]
    >[number]["node"]["__typename"][] = polls
      ? [
          "DiscoveryV4GalleryStory",
          "DiscoveryV4BrandStory",
          "DiscoveryV4PollStory",
        ]
      : ["DiscoveryV4GalleryStory", "DiscoveryV4BrandStory"];
    return (
      summaries?.edges.filter((i) =>
        allowedTypes.includes(i.node.__typename)
      ) || []
    );
  }, [summaries, polls]);

  return (
    <>
      <div class="summaries-header">
        <div class="summaries-header-text">
          <h2>Summaries of top threads</h2>
          {summaries?.lastUpdated && (
            <span>
              Last updated <RelativeDate date={summaries?.lastUpdated || 0} />
            </span>
          )}
        </div>
        <button
          type="button"
          class="button button--alt"
          onClick={toggleCategories}
          data-gtm={gtmSummariesOpenfilters}
        >
          Filter by forums {(count && ` (${count})`) || null}
        </button>
      </div>
      <Modal
        isOpen={categoriesOpen}
        onClose={toggleCategories}
        header="Filter by forums"
        className="summaries-categories-modal"
      >
        <SummariesCategories
          categories={categories}
          selected={selectedCategories}
          setCategories={setCategories}
          clearCategories={clearCategories}
          applyCategories={applyCategories}
        />
      </Modal>
      {state === "loaded" && !edges.length && selectedCategories.length > 0 && (
        <div class="categories-null">
          <strong>
            No results for this filter, but we&apos;ve got more to show you.
          </strong>
          <p>Explore by trying different filters or reset your search</p>
          <button
            type="button"
            class="button button--plain"
            onClick={clearCategories}
            data-gtm={gtmCategoriesClear}
          >
            Clear filter
          </button>
        </div>
      )}
      {(edges.length ?? 0) > 0 && (
        <ol class="summaries">
          {edges.map((i, idx) => (
            <li key={i.node.id} class="summaries-summary">
              <Summary first={idx === 0} summary={i} />
            </li>
          ))}
        </ol>
      )}
      {(state === "error" ||
        (state === "loaded" && !edges.length && !selectedCategories.length)) &&
        XF.phrase("oops_we_ran_into_some_problems_more_details_console")}
      {state === "loading" && <Loader />}
      {state === "loaded" &&
        !!edges.length &&
        (summaries?.pageInfo.hasNextPage ? (
          <button
            type="button"
            class="button button--alt feed-button"
            value={summaries.pageInfo.endCursor}
            onClick={loadMore}
            data-gtm={gtmSummariesLoadmore}
            data-gtm-context={summaries.pageInfo.endCursor}
          >
            Load more
          </button>
        ) : (
          <div class="categories-null">
            <strong>You&apos;ve explored it all!</strong>
            <p>Come back tomorrow for fresh insights and summaries.</p>
            <p>Meanwhile, why not explore related topics?</p>
            <a href="#top" data-gtm={gtmSummariesEnd}>
              Back to top
            </a>
          </div>
        ))}
    </>
  );
}
