import clsx from "clsx";
import { ComponentProps } from "preact";
import {
  gtmSummaryNode,
  gtmSummaryReadmore,
  gtmSummaryTitle,
} from "../../../common/gtm-ids";
import { getCFSrc } from "../../../common/helper";
import { toGtmContext } from "../../../common/toGtmContext";
import { toSlug } from "../../../common/toSlug";
import { getSummaries } from "../../services/feed-content";
import { BBCode } from "../BBCode/BBCode";
import { Byline } from "../Byline/Byline";

export function SummaryBase({
  first,
  summary,
  children,
  ...props
}: Omit<ComponentProps<"article">, "summary"> & {
  first?: boolean;
  summary: Awaited<ReturnType<typeof getSummaries>>["edges"][number];
}) {
  const urlNode = `/forums/${toSlug(summary.node.nodeTitle)}.${
    summary.node.nodeId
  }`;
  const urlThread = `/threads/${toSlug(summary.node.title)}.${summary.node.id
    .split(":")
    .pop()}`;
  const urlPost = `/posts/${summary.node.firstPost.id}`;
  const firstImage = summary.node.firstPost.images?.[0];

  return (
    <article {...props} class={clsx("summary", props.class)}>
      <header>
        <a
          href={urlNode}
          class="summary-node"
          data-gtm={gtmSummaryNode}
          data-gtm-context={toGtmContext({
            nodeId: summary.node.nodeId,
            storyId: summary.node.id,
          })}
        >
          {summary.node.nodeTitle}
        </a>
        <a
          href={urlThread}
          class="summary-title"
          data-gtm={gtmSummaryTitle}
          data-gtm-context={summary.node.id}
        >
          <h3>{summary.node.title}</h3>
        </a>
        <Byline
          userid={summary.node.firstPost.author.id}
          username={summary.node.firstPost.author.username}
          avatarUrl={summary.node.firstPost.author.avatarUrl}
          date={summary.node.firstPost.date}
          urlDate={urlPost}
          replies={summary.node.postsCount - 1}
          gtmContext={{ storyId: summary.node.id }}
        />
      </header>
      <div class="summary-body">
        <BBCode media={false} class="summary-text">
          {summary.node.firstPost.text}
        </BBCode>
        {firstImage &&
          summary.node.__typename !== "DiscoveryV4GalleryStory" && (
            <img
              class="summary-first-image"
              src={getCFSrc(320, firstImage.url || "")}
              width={firstImage.width}
              height={firstImage.height}
              alt={`Preview of ${summary.node.firstPost.author.username}'s post`}
              loading={first ? "eager" : "lazy"}
            />
          )}
      </div>
      <a
        class="summary-readmore"
        href={urlPost}
        data-gtm={gtmSummaryReadmore}
        data-gtm-context={summary.node.id}
      >
        Read more
      </a>
      {children}
    </article>
  );
}
