import { ComponentProps } from "preact";
import { useMemo } from "preact/hooks";
import {
  gtmSummaryPollMentions,
  gtmSummaryPollReplies,
} from "../../../common/gtm-ids";
import { numToAlphabet } from "../../../common/numToAlphabet";
import { toSlug } from "../../../common/toSlug";
import Poll from "./Poll";
import { SummaryBase } from "./SummaryBase";
import { SummaryMentionGroups } from "./SummaryMentionGroups";

export function SummaryPoll({
  summary,
  ...props
}: ComponentProps<typeof SummaryBase> & {
  summary: ComponentProps<typeof SummaryBase>["summary"] & {
    node: { __typename: "DiscoveryV4PollStory" };
  };
}) {
  const groups = useMemo(
    () =>
      summary.node.poll.responses.map((i, idx) => ({
        key: i.id,
        title: `${numToAlphabet(idx)}. ${i.responseText}`,
        highlight: i.responseText,
        mentions: i.posts,
      })),
    [summary.node.poll.responses]
  );
  const threadId = useMemo(
    () => summary.node.id.split(":").pop() || "",
    [summary.node.id]
  );
  const urlThread = `/threads/${toSlug(summary.node.title)}.${threadId}`;
  const showMentions = useMemo(
    () => groups.some((i) => i.mentions.length),
    [groups]
  );
  return (
    <SummaryBase {...props} summary={summary}>
      <Poll threadId={threadId} poll={summary.node.poll} />
      {showMentions ? (
        <>
          <div class="summary-count">Posts by poll responses</div>
          <SummaryMentionGroups
            summary={summary}
            groups={groups}
            gtmGroup={gtmSummaryPollMentions}
          />
        </>
      ) : (
        <div class="summary-count">
          <a
            href={urlThread}
            data-gtm={gtmSummaryPollReplies}
            data-gtm-context={summary.node.id}
          >
            See {summary.node.postsCount - 1}{" "}
            {summary.node.postsCount - 1 === 1 ? "reply" : "replies"} in thread
          </a>
        </div>
      )}
    </SummaryBase>
  );
}
