/* eslint-disable max-classes-per-file, no-underscore-dangle */
import { Parser, Tag } from "bbcodejs";
import { ComponentProps } from "preact";

import clsx from "clsx";
import "./BBCode.less";

class TagAttach extends Tag {
  _toHTML() {
    return `<img loading="lazy" ${
      this.params.width ? `width="${this.params.width}"` : ""
    } src="//${
      window.location.host
    }/attachments/${this.getContent()}" alt="attached media" />`;
  }
}

class TagUrl extends Tag {
  _toHTML() {
    return `<a href="${(this.params.url || this.getContent()).replace(
      /^'|'$/g,
      ""
    )}" target="_blank" rel="noopener noreferrer">${this.getContent().replace(
      /^(https?:\/\/)?(www\.)?/i,
      ""
    )}</a>`;
  }
}

class TagUser extends Tag {
  _toHTML() {
    return `<a href="${
      window.location.host
    }/members/${this.getContent().replace(/^@/, "")}.${
      this.params.user
    }" target="_blank" rel="noopener noreferrer">${this.getContent()}</a>`;
  }
}

class TagEmoji extends Tag {
  _toHTML() {
    return `<img loading="lazy" width="23px" height="23px" class="emoji" src="https://images.platforum.cloud/emojis/emoji${this.params.emoji}.png" alt="tapatalk emoji ${this.params.emoji}" />`;
  }
}

class TagMedia extends Tag {
  _toHTML() {
    if (this.params.media === "imgur") {
      return `<a href="//imgur.com/a/${this.getContent()}">View ${this.getContent()} on imgur</a>`;
    }
    if (this.params.media === "youtube") {
      return `<iframe loading="lazy" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="1280" height="720" style="width: 100%; height: auto; aspect-ratio: 16 / 9;" type="text/html" src="https://youtube.com/embed/${this.getContent()}"></iframe>`;
    }
    return `Unsupported media type "${
      this.params.media
    }" - ${this.getContent()}`;
  }
}

class TagGallery extends Tag {
  _toHTML() {
    const { type, id } = this.params;
    if (type === "media") {
      return `<a href="//${
        window.location.host
      }/media/${id}"><img loading="lazy" ${
        this.params.width ? `width="${this.params.width}"` : ""
      } src="//${
        window.location.host
      }/media/${id}/full" alt="gallery media" /></a>`;
    }
    if (type === "album") {
      return `<a href="//${window.location.host}/media/albums/${id}">Album ${id}</a>`;
    }
    return `Unsupported gallery type "${type}" - ${id}`;
  }
}

class TagQuote extends Tag {
  _toHTML() {
    const [user /* , post, member */] = (this.params.quote || "").split(", ");
    return `<blockquote><cite>${user}</cite>${this.getContent()}</blockquote>`;
  }
}

class TagPassthrough extends Tag {
  _toHTML() {
    return this.getContent();
  }
}

class TagIgnore extends Tag {
  // eslint-disable-next-line class-methods-use-this
  _toHTML() {
    return "";
  }
}

const parsers: { [key: string]: typeof Parser } = {};
function getParser({ media, quotes }: { media: boolean; quotes: boolean }) {
  const key = `${media}-${quotes}`;
  let parser = parsers[key];
  if (parser) return parser;
  parser = new Parser();
  parsers[key] = parser;
  parser.registerTag("url", TagUrl);
  parser.registerTag("user", TagUser);
  parser.registerTag("emoji", TagEmoji);
  parser.registerTag("size", TagPassthrough);
  parser.registerTag("font", TagPassthrough);
  if (!media) parser.registerTag("img", TagIgnore);
  parser.registerTag("attach", media ? TagAttach : TagIgnore);
  parser.registerTag("media", media ? TagMedia : TagIgnore);
  parser.registerTag("gallery", media ? TagGallery : TagIgnore);
  parser.registerTag("quote", quotes ? TagQuote : TagIgnore);
  return parser;
}

export function BBCode({
  children,
  media = true,
  quotes = true,
  ...props
}: Omit<ComponentProps<"div">, "media"> & {
  children: string;
  media?: boolean;
  quotes?: boolean;
}) {
  return (
    <div
      {...props}
      class={clsx(props.class, "bbcodejs")}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: getParser({ media, quotes })
          .toHTML(
            children
              .replace(/\[emoji(\d+?)]/gi, `[emoji=$1]`) // parameterize tapatalk emoji
              .replace(/\[gallery=(\w+?), (\d+?)]/gi, `[gallery type=$1 id=$2]`) // parameterize galleries
              .replace(/\n\n+/g, "\n") // remove redundant whitespace
          )
          // replace <br /> elements with newlines
          .replaceAll("<br />", "\n")
          .trim(),
      }}
    />
  );
}
