import { ComponentProps } from "preact";
import { useCallback, useMemo, useState } from "preact/hooks";
import {
  gtmSummaryPollSeeresults,
  gtmSummaryPollVote,
} from "../../../common/gtm-ids";
import { numToAlphabet } from "../../../common/numToAlphabet";
import { partition } from "../../../common/partition";
import { getSummaries } from "../../services/feed-content";
import { voteOnPoll } from "../../services/polls";
import { ShortNumber } from "../ShortNumber/ShortNumber";

const isLoggedIn = document.documentElement.dataset.loggedIn === "true";

export default function Poll({
  threadId,
  poll: {
    question,
    responses: responsesInitial,
    canViewResultsWithoutVoting,
    closeDate,
    userVotes: userVotesInitial,
  },
}: {
  threadId: string;
  poll: (Awaited<ReturnType<typeof getSummaries>>["edges"][number]["node"] & {
    __typename: "DiscoveryV4PollStory";
  })["poll"];
}) {
  const alreadyVoted = !!userVotesInitial.length;
  const isClosed = useMemo(
    () => !!closeDate && Date.now() > closeDate * 1000,
    [closeDate]
  );
  const canShowResults = isClosed || canViewResultsWithoutVoting;
  const forceResultsDefault = isClosed || alreadyVoted;

  const [forceResults, setForceResults] =
    useState<boolean>(forceResultsDefault);
  const [userVotes, setUserVotes] = useState(userVotesInitial);

  // recreate responses with flag indicating whether user voted on them,
  // and add 1 to their count if the user's vote is only present in the local state
  const mockUserVoteCount = useMemo(
    () => JSON.stringify(userVotes) !== JSON.stringify(userVotesInitial),
    [userVotes, userVotesInitial]
  );
  const responses = useMemo(
    () =>
      responsesInitial.map((i) => {
        const userVotedOnThis = userVotes.includes(parseInt(i.id, 10));
        return {
          ...i,
          userVotedOnThis,
          votesCount:
            i.votesCount + (mockUserVoteCount && userVotedOnThis ? 1 : 0),
        };
      }),
    [responsesInitial, mockUserVoteCount, userVotes]
  );

  const ordered = useMemo(
    () => responses.slice().sort((a, b) => b.votesCount - a.votesCount),
    [responses]
  );
  const winners = useMemo(
    () => partition(ordered, (i) => i.votesCount === ordered[0].votesCount)[0],
    [ordered]
  );
  const results = forceResults || userVotes.length > 0;
  const showResults = useCallback(() => setForceResults(true), []);
  const vote = useCallback<NonNullable<ComponentProps<"button">["onClick"]>>(
    async (event) => {
      const optimistic = isLoggedIn;
      const idx = parseInt(event.currentTarget.value, 10);
      if (optimistic) setUserVotes([idx]);
      try {
        await voteOnPoll(
          threadId,
          event.currentTarget.dataset.responseId || ""
        );
        if (!optimistic) setUserVotes([idx]);
      } catch (err) {
        console.error("Failed to vote on poll", err);
        setUserVotes([]);
      }
    },
    [threadId]
  );
  const totalVotes = useMemo(
    () => responses.reduce((acc, i) => acc + i.votesCount, 0),
    [responses]
  );

  return (
    <section class="summary-poll">
      <header class="summary-poll_header">
        <h2>{question}</h2>
      </header>
      <ul class="summary-poll_options">
        {responses.map((i, idx) => (
          <li
            class="summary-poll_option"
            key={i.id}
            data-winner={winners.some((w) => w === i)}
          >
            {results && (
              <span
                aria-hidden
                class="summary-poll_bar"
                style={{
                  animationDelay: `${idx * 100}ms`,
                  width: `${Math.round(
                    (i.votesCount / totalVotes || 0) * 100
                  )}%`,
                }}
              />
            )}
            <button
              data-gtm={gtmSummaryPollVote}
              data-gtm-context={i.responseText}
              title={i.responseText}
              value={i.id}
              data-response-id={i.id}
              type="button"
              class="button button--alt summary-poll_option-btn"
              onClick={vote}
              disabled={results}
            >
              <span class="summary-poll_option-title">
                {numToAlphabet(idx)}. {i.responseText}
              </span>
              {i.userVotedOnThis && (
                <i class="fa fa-check-circle" title="Voted" />
              )}
              {results && (
                <span class="summary-poll_percent">
                  {Math.round((i.votesCount / totalVotes || 0) * 100)}
                </span>
              )}
            </button>
          </li>
        ))}
      </ul>
      <footer class="summary-poll_footer">
        <span class="summary-poll_total">
          {!canShowResults && !results ? (
            "Results are only viewable after voting."
          ) : (
            <>
              <ShortNumber number={totalVotes} /> total votes
            </>
          )}
        </span>
        <button
          type="button"
          data-gtm={gtmSummaryPollSeeresults}
          class="button button--plain summary-poll_seeresults"
          onClick={showResults}
          disabled={!canShowResults || results}
        >
          See result
        </button>
      </footer>
    </section>
  );
}
