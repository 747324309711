import clsx from "clsx";
import { ComponentChildren } from "preact";
import ReactModal from "react-modal";
import "./Modal.less";

ReactModal.setAppElement(".p-pageWrapper");

export function Modal({
  isOpen,
  onClose,
  header,
  children,
  className,
}: {
  isOpen: boolean;
  onClose: () => void;
  header: ComponentChildren;
  children: ComponentChildren;
  className?: string;
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      className={clsx("Modal", className)}
    >
      <header>
        <h2>{header}</h2>
        <button
          type="button"
          class="btn-dismiss"
          onClick={onClose}
          title="Close"
        >
          <i class="fa fa-times" aria-hidden="true" />
        </button>
      </header>
      {children}
    </ReactModal>
  );
}
