import { XF } from "../../XF";

/** Retrieves list of top forums from back end */
export async function getTopForums() {
  const result = await XF.ajax(
    "get",
    "/v1/nodes/top-forums",
    undefined,
    undefined,
    { skipDefault: true }
  );
  // defined in `Node.php::actionGetTopForums`
  return result.message as {
    title: string;
    url: string;
    views: number;
    messageCount: number;
  }[];
}
