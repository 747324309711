import clsx from "clsx";
import { ComponentProps } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { XF } from "../../../XF";
import { gtmHometabsTab } from "../../../common/gtm-ids";
import { sendDataLayerEvent } from "../../../dataLayer";
import { Feed } from "../Feed/Feed";
import { Summaries } from "../Summaries/Summaries";

const storageKey = "home-tabs-selected";

const tabs = ["feed", "summaries"] as const;
type Tabs = (typeof tabs)[number];

let initialSelected = XF.LocalStorage.get(storageKey) || "";
if (!(tabs as readonly string[]).includes(initialSelected)) {
  initialSelected = "";
}

export function Home({
  summaries,
  summariesIsDefault,
  numFollowed,
  polls,
  useHtmlFeed,
}: {
  summaries?: boolean;
  summariesIsDefault?: boolean;
  numFollowed?: number;
  polls?: boolean;
  useHtmlFeed?: boolean;
}) {
  const [selected, setSelected] = useState<Tabs>(
    (initialSelected || (summariesIsDefault ? "summaries" : "feed")) as Tabs
  );
  const onClick = useCallback<NonNullable<ComponentProps<"button">["onClick"]>>(
    (event) => {
      setSelected(event.currentTarget.value as Tabs);
      XF.LocalStorage.set(storageKey, event.currentTarget.value);
    },
    []
  );

  // track which tab is selected even if user hasn't interacted
  useEffect(() => {
    if (!summaries) return; // ignored when feature flag is disabled
    sendDataLayerEvent("feedLoad", {
      feedLoad: selected,
    });
  }, [summaries, selected]);

  return summaries ? (
    <>
      <nav class="home-tabs">
        <button
          type="button"
          value="feed"
          class={clsx("button button--alt", { selected: selected === "feed" })}
          onClick={onClick}
          data-gtm={gtmHometabsTab}
          data-gtm-context="feed"
        >
          Home
        </button>
        <button
          type="button"
          value="summaries"
          class={clsx("button button--alt", {
            selected: selected === "summaries",
          })}
          onClick={onClick}
          data-gtm={gtmHometabsTab}
          data-gtm-context="summaries"
        >
          Summaries
          <span class="message-newTag">NEW</span>
        </button>
      </nav>
      {selected === "feed" && <Feed useHtmlFeed={useHtmlFeed} />}
      {selected === "summaries" && (
        <Summaries numFollowed={numFollowed || 0} polls={polls} />
      )}
    </>
  ) : (
    <Feed useHtmlFeed={useHtmlFeed} />
  );
}
