/** @returns 0 -> A, 1 -> B, ..., 25 -> Z, 26 -> AA, etc */
export function numToAlphabet(num: number) {
  let result = "";
  let remainder = num;
  const A = "A".codePointAt(0) as number;
  while (remainder > -1) {
    result = `${String.fromCodePoint((remainder % 26) + A)}${result}`;
    remainder -= remainder % 26;
    remainder /= 26;
    remainder -= 1;
  }
  return result;
}
