import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "preact/hooks";
import { XF } from "../../../XF";
import { gtmHomepageLoadmore } from "../../../common/gtm-ids";
import { strings } from "../../homepage-strings";
import { getFeedContent } from "../../services/feed-content";
import { FeedItemLists } from "../FeedItemList/FeedItemList";
import { Loader } from "../Loader/Loader";

export function Feed({ useHtmlFeed }: { useHtmlFeed?: boolean }) {
  const featuredThreadIds = useMemo(
    () =>
      Array.from(document.querySelectorAll(".featured-thread__item")).map(
        (i) => i.getAttribute("threadId") || ""
      ),
    []
  );

  const [page, setPage] = useState(1);
  const [pageInitial, setPageInitial] = useState(1);
  const [feed, setFeed] = useState<
    Awaited<ReturnType<typeof getFeedContent>>["data"]
  >([]);
  const [enableCF, setEnableCF] = useState(false);
  const [state, setState] = useState<"error" | "loading" | "loaded" | "end">(
    "loading"
  );

  // Calls API to get feed content
  const getFeed = useCallback(async () => {
    if (pageInitial !== 1 && page <= pageInitial) return; // dont try to load cached pages beyond p1
    try {
      setState("loading");
      let response: Awaited<ReturnType<typeof getFeedContent>> | null = null;

      // if it's the first page and we got here via history, load from cache
      if (
        page === 1 &&
        (
          window.performance?.getEntriesByType(
            "navigation"
          ) as PerformanceNavigationTiming[]
        )[0]?.type === "back_forward"
      ) {
        try {
          const cache = XF.LocalStorage.getJson<
            Awaited<ReturnType<typeof getFeedContent>> & {
              page: number;
            }
          >("homepageResults");
          response = cache;
          setPageInitial(cache.page);
        } catch (err) {
          response = null;
          setPageInitial(1);
          setPage(1);
        }
      }
      // either we didn't use the cache, or it failed, so fetch fresh data
      if (!response) {
        response = await getFeedContent(page, featuredThreadIds, useHtmlFeed);
      }

      const { data } = response;
      setFeed((s: []) => s.concat(data));
      setEnableCF(response.enableCF);
      setState(response.hasMore ? "loaded" : "end");
    } catch (error) {
      console.error(error);
      setState("error");
    }
  }, [page, pageInitial, featuredThreadIds, useHtmlFeed]);

  useEffect(() => {
    getFeed();
  }, [getFeed]);
  useEffect(() => {
    if (feed.length)
      XF.LocalStorage.setJson("homepageResults", {
        data: feed,
        enableCF,
        hasMore: state !== "end",
        page,
      });
  }, [enableCF, feed, page, state]);

  const refList = useRef<HTMLOListElement>(null);
  useEffect(() => {
    if (feed.length && refList.current) {
      XF.activate(refList.current);
    }
  }, [feed]);

  const loadMore = useCallback(() => {
    setPage((s) => Math.max(s, pageInitial) + 1);
  }, [pageInitial]);

  if (state === "error")
    return (
      <>{XF.phrase("oops_we_ran_into_some_problems_more_details_console")}</>
    );
  return (
    <>
      {typeof feed[0] !== "string" ? (
        <ol
          ref={refList}
          class="feedItem-list-container california-thread-list block-body"
          data-state={state}
        >
          <FeedItemLists data={feed} enableCF={enableCF} showFeedAd />
        </ol>
      ) : (
        <ol
          class="feedItem-list-container california-thread-list block-body"
          data-state={state}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: feed?.join("\n") || "" }}
        />
      )}
      <button
        disabled={state !== "loaded"}
        type="button"
        class="button button--alt feed-button"
        onClick={loadMore}
        data-gtm={gtmHomepageLoadmore}
        data-gtm-context={page}
      >
        {strings.LOAD_MORE}
      </button>
      {state !== "end" && <Loader />}
    </>
  );
}
