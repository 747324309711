/** @returns two arrays, where the first contains all items that satisfy the predicate, and the second contains all other items */
export function partition<T>(arr: T[], predicate: (item: T) => boolean) {
  return arr.reduce<[T[], T[]]>(
    (acc, i) => {
      acc[predicate(i) ? 0 : 1].push(i);
      return acc;
    },
    [[], []]
  );
}
