import { ComponentProps } from "preact";
import { getSummaries } from "../../services/feed-content";
import { SummaryBrand } from "./SummaryBrand";
import { SummaryGallery } from "./SummaryGallery";
import { SummaryPoll } from "./SummaryPoll";

export function Summary({
  summary,
  ...props
}: Omit<
  ComponentProps<typeof SummaryGallery | typeof SummaryBrand>,
  "summary"
> & { summary: Awaited<ReturnType<typeof getSummaries>>["edges"][number] }) {
  switch (summary.node.__typename) {
    case "DiscoveryV4PollStory":
      return (
        <SummaryPoll
          {...props}
          summary={
            summary as typeof summary & {
              node: { __typename: "DiscoveryV4PollStory" };
            }
          }
        />
      );
    case "DiscoveryV4GalleryStory":
      return (
        <SummaryGallery
          {...props}
          summary={
            summary as typeof summary & {
              node: { __typename: "DiscoveryV4GalleryStory" };
            }
          }
        />
      );
    case "DiscoveryV4BrandStory":
      return (
        <SummaryBrand
          {...props}
          summary={
            summary as typeof summary & {
              node: { __typename: "DiscoveryV4BrandStory" };
            }
          }
        />
      );
    default:
      return (
        <article>{(summary.node as { __typename: string }).__typename}</article>
      );
  }
}
